import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import FeatureReviews from "./FeatureReviews";

export const REVIEW_ROUTES = [
  <Route
    path="/home/reviews"
    element={
      <PrivateRoute>
        <Layout
          component={FeatureReviews}
          path="/home/reviews"
          title="Reviews"
        ></Layout>
      </PrivateRoute>
    }
    key={"HOME_ROUTE"}
  />,
];

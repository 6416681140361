import { AutoAwesome, Close, Sync } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Rating,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { perfParameters } from "../constant/constant";

const PerfCommentDialog = ({
  user,
  pm,
  startDate,
  astId,
  pmId,
  open,
  onClose,
  ratings,
  validation,
  handleCommentChange,
  handleChange,
  selfratingCheckingFunction,
  saving,
  page_Index,
}) => {
  const [pageIndex, setPageIndex] = useState(page_Index ?? 0);
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [commentHistory, setCommentHistory] = useState({});
  const scrollRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const groupedParameters = Object.values(
    perfParameters.reduce((acc, param) => {
      if (!acc[param.Objective]) acc[param.Objective] = [];
      acc[param.Objective].push(param);
      return acc;
    }, {})
  );

  useEffect(() => {
    axiosInstance
      .post(`/user/comment/history`, {
        startDate,
        userId: astId,
        managerId: pmId,
        rowIndex: index,
        subIndex: subIndex,
      })
      .then((res) => setCommentHistory(res.data))
      .catch((err) => console.log(err));
  }, [index, subIndex]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [commentHistory]);

  const handleNavigation = (direction) => {
    if (direction === "Prev" && pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    } else if (
      direction === "Next" &&
      pageIndex < groupedParameters.length - 1
    ) {
      setPageIndex(pageIndex + 1);
    }
  };

  const enhanceWithBunai = async (index, subIndex, comment, context) => {
    if (!comment.trim()) return;
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        "https://bunai.edvenswatech.com/service/enhancetext",
        {
          input_text: comment,
          context,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.response) {
        handleCommentChange(index, subIndex, response.data?.response);
      }
    } catch (error) {
      console.error("Error enhancing text:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      sx={{ "& .MuiDialog-paper": { height: "600px" } }}
      maxWidth="lg"
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <Tooltip title="Close">
          <Close
            sx={{
              position: "absolute",
              top: "0",
              right: "0",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </Tooltip>
        <Stack
          direction={"row"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            {groupedParameters[pageIndex][0]?.Objective}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            {saving ? <Typography>Syncing...</Typography> : ""}
            <Tooltip title={"Sync"}>
              <Sync
                sx={{
                  fontSize: 24,
                  animation: saving ? "spin 1s linear infinite" : "none",
                  "@keyframes spin": {
                    "0%": { transform: "rotate(0deg)" },
                    "100%": { transform: "rotate(360deg)" },
                  },
                }}
              />
            </Tooltip>
          </Stack>
        </Stack>

        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "410px",
            border: "1px solid #ccc",
            padding: "10px",
          }}
        >
          {groupedParameters[pageIndex].map((perfObj) => (
            <Accordion key={`${perfObj.index}-${perfObj.subIndex}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 18, fontWeight: "bolder", flexGrow: 1 }}
                      >
                        {perfObj.title}
                      </Typography>

                      <Rating
                        value={
                          ratings.find(
                            (r) =>
                              r.rowIndex === perfObj.index &&
                              r.subIndex === perfObj.subIndex
                          )?.rating || 0
                        }
                        onChange={(event, newValue) =>
                          handleChange(
                            perfObj.index,
                            perfObj.subIndex,
                            newValue,
                            perfObj.weightage
                          )
                        }
                        precision={0.5}
                        readOnly={selfratingCheckingFunction()}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ fontSize: 15 }}>{perfObj.desc}</Typography>
                <Grid container spacing={1} sx={{ mt: 1 }}>
                  {!selfratingCheckingFunction() && (
                    <Grid item xs={12} sx={{ position: "relative" }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter your comments..."
                        value={
                          ratings.find(
                            (r) =>
                              r.rowIndex === perfObj.index &&
                              r.subIndex === perfObj.subIndex
                          )?.comment || ""
                        }
                        onChange={(event) =>
                          handleCommentChange(
                            perfObj.index,
                            perfObj.subIndex,
                            event.target.value
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title={loading ? "Enhancing..." : "Enhance"}
                              >
                                <IconButton
                                  size="small"
                                  sx={{
                                    position: "absolute",
                                    bottom: 10,
                                    right: 10,
                                    color: "white",
                                    backgroundColor: "teal",
                                    borderRadius: "50%",
                                    width: 36,
                                    height: 36,
                                    "&:hover": { backgroundColor: "teal" },
                                  }}
                                  onClick={() =>
                                    enhanceWithBunai(
                                      perfObj.index,
                                      perfObj.subIndex,
                                      ratings.find(
                                        (r) =>
                                          r.rowIndex === perfObj.index &&
                                          r.subIndex === perfObj.subIndex
                                      )?.comment || "",
                                      perfObj.keyfocusarea
                                    )
                                  }
                                >
                                  <motion.div
                                    animate={loading ? { rotate: 360 } : {}}
                                    transition={
                                      loading
                                        ? {
                                            repeat: Infinity,
                                            duration: 1,
                                            ease: "linear",
                                          }
                                        : {}
                                    }
                                  >
                                    <AutoAwesome />
                                  </motion.div>
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {validation[`${perfObj.index}-${perfObj.subIndex}`] && (
                        <Typography color="error" fontSize={12} mt={1}>
                          {validation[`${perfObj.index}-${perfObj.subIndex}`]}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Accordion
                      expanded={expanded === perfObj.index}
                      onChange={(event, isExpanded) => {
                        setExpanded(isExpanded ? perfObj.index : null);
                        if (isExpanded) {
                          setIndex(perfObj.index);
                          setSubIndex(perfObj.subIndex);
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <IconButton
                            onClick={(event) => {
                              setIndex(perfObj.index);
                              setSubIndex(perfObj.subIndex);
                            }}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        }
                      >
                        <Typography sx={{ fontSize: 14, fontWeight: "bolder" }}>
                          View Previous Comments
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          ref={scrollRef}
                          sx={{
                            height: selfratingCheckingFunction()
                              ? "300px"
                              : "250px",
                            overflowY: "auto",
                            p: 1,
                            borderRadius: "10px",
                            border: "1px solid #ccc",
                            bgcolor: "#f9f9f9",
                          }}
                        >
                          <Grid item sx={12} container spacing={1}>
                            {commentHistory.length > 0 &&
                              commentHistory.map((c, index) => (
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  justifyContent={
                                    c.submittedByUser && !c.submittedByManager
                                      ? "flex-end"
                                      : "flex-start"
                                  }
                                >
                                  <Grid key={index} item xs={6}>
                                    <Stack direction={"column"} spacing={1}>
                                      <Paper
                                        elevation={3}
                                        sx={{
                                          p: 1,
                                          maxWidth: "100%",
                                          backgroundColor:
                                            c.submittedByUser &&
                                            !c.submittedByManager
                                              ? "lightgreen"
                                              : "white",
                                          borderRadius:
                                            c.submittedByUser &&
                                            !c.submittedByManager
                                              ? "10px 10px 10px 0px"
                                              : "10px 10px 0px 10px",
                                        }}
                                      >
                                        <Typography
                                          sx={{ fontSize: 14 }}
                                          variant="body1"
                                        >
                                          {c.comment}
                                        </Typography>
                                      </Paper>
                                      <Typography
                                        textAlign={
                                          c.submittedByUser &&
                                          !c.submittedByManager
                                            ? "end"
                                            : "start"
                                        }
                                        variant="caption"
                                        sx={{ fontSize: 10 }}
                                      >
                                        {c.submittedByUser &&
                                        !c.submittedByManager
                                          ? `${user} ${moment(
                                              c.rateEndTime,
                                              "YYYY-MM-DD HH:mm"
                                            ).format("DD-MMM-YYYY hh:mmA")}`
                                          : `${pm} ${moment(
                                              c.rateEndTime,
                                              "YYYY-MM-DD HH:mm"
                                            ).format("DD-MMM-YYYY hh:mmA")}`}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              onClick={() => handleNavigation("Prev")}
              size="small"
              variant="contained"
              disabled={pageIndex === 0}
            >
              Prev
            </Button>
          </Grid>
          <Grid item>
            {pageIndex + 1}/{groupedParameters.length}
          </Grid>
          <Grid item>
            {pageIndex < groupedParameters.length - 1 ? (
              <Button
                onClick={() => handleNavigation("Next")}
                size="small"
                variant="contained"
              >
                Next
              </Button>
            ) : (
              <Button onClick={onClose} size="small" variant="contained">
                Close
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PerfCommentDialog;
